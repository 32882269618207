var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageheading } }),
      _c(
        "div",
        {
          staticClass: "has-text-centered has-background-primary",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "is-size-6 has-text-white",
              staticStyle: { padding: "5px 0px" },
            },
            [_vm._v(_vm._s(_vm.pageheading.toLocaleUpperCase()))]
          ),
        ]
      ),
      _c("div", { staticStyle: { "max-width": "95%", margin: "auto" } }, [
        _c("div", { attrs: { id: "body-content-area" } }, [
          !_vm.uploadedDocuments || _vm.isLoadingDocuments
            ? _c("div", [_c("Loading")], 1)
            : _c(
                "div",
                { attrs: { id: "pagelayout" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "top-bar-spacer",
                      staticStyle: { height: "8px", width: "100%" },
                    },
                    [_vm._v(" ")]
                  ),
                  _c("div", { staticClass: "top-level-bar" }, [
                    _c(
                      "div",
                      {
                        staticClass: "control grid-button top-bar",
                        staticStyle: {
                          display: "flex",
                          "justify-content": "flex-end",
                          "margin-bottom": "1rem",
                        },
                      },
                      [
                        _c("div", { attrs: { id: "buttongroup" } }, [
                          _c("div", { staticClass: "level" }, [
                            _c("div", { staticClass: "level-left" }, [
                              _c("div", { staticClass: "field is-grouped" }, [
                                _c("div", { staticClass: "control" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "button is-dark",
                                      attrs: {
                                        disabled: _vm.selectedRows.length === 0,
                                      },
                                      on: { click: _vm.openDeleteModal },
                                    },
                                    [
                                      _vm._m(0),
                                      _c("span", [_vm._v(" Delete Selected ")]),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "control" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "button is-accent",
                                      attrs: {
                                        disabled: _vm.selectedRows.length === 0,
                                      },
                                      on: { click: _vm.downloadSelected },
                                    },
                                    [
                                      _vm._m(1),
                                      _c("span", [
                                        _vm._v(" Download Selected "),
                                      ]),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "control" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "button is-light",
                                      attrs: {
                                        disabled: _vm.isLoadingDocuments,
                                      },
                                      on: { click: _vm.getDocuments },
                                    },
                                    [
                                      _c("span", { staticClass: "icon" }, [
                                        !_vm.isLoadingDocuments
                                          ? _c("i", {
                                              staticClass: "fas fa-sync-alt",
                                            })
                                          : _c("i", {
                                              staticClass:
                                                "fas fa-spinner fa-spin",
                                            }),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                  _c("Grid", {
                    staticClass: "search",
                    style: {
                      maxHeight: _vm.maxHeight + "px",
                      height: _vm.maxHeight + "px",
                    },
                    attrs: {
                      pageable: Object.assign({}, _vm.pageable, {
                        pageSizes: _vm.pageSizes,
                      }),
                      filter: _vm.filter,
                      "data-items": _vm.uploadedDocuments,
                      sortable: true,
                      "selected-field": _vm.selectedField,
                      sort: _vm.sort,
                      filterable: false,
                      "page-size": 20,
                      skip: _vm.skip,
                      take: _vm.take,
                      total: _vm.totalDocuments,
                      columns: _vm.columns,
                    },
                    on: {
                      selectionchange: _vm.onSelectionChange,
                      headerselectionchange: _vm.onHeaderSelectionChange,
                      filterchange: _vm.filterchange,
                      sortchange: _vm.sortchange,
                      pagechange: _vm.pagechange,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "NameCell",
                        fn: function (ref) {
                          var props = ref.props
                          return [
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  overflow: "hidden",
                                  "text-overflow": "ellipsis",
                                  "word-wrap": "none",
                                  "white-space": "nowrap",
                                },
                              },
                              [_vm._v(" " + _vm._s(props.dataItem.name) + " ")]
                            ),
                          ]
                        },
                      },
                      {
                        key: "TypeCell",
                        fn: function (ref) {
                          var props = ref.props
                          return [
                            _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    props.dataItem.extension
                                      .replace(".", "")
                                      .toUpperCase()
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "SizeCell",
                        fn: function (ref) {
                          var props = ref.props
                          return [
                            _c(
                              "td",
                              { staticStyle: { "text-align": "right" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getFileSize(props.dataItem.fileSize)
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "UploadDateCell",
                        fn: function (ref) {
                          var props = ref.props
                          return [
                            _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("moment")(
                                      props.dataItem.uploadDate,
                                      "timezone",
                                      _vm.getTimeZone(),
                                      "MM/DD/YYYY"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "UploadedByCell",
                        fn: function (ref) {
                          var props = ref.props
                          return [
                            _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(props.dataItem.uploadUserName) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "ActionCell",
                        fn: function (ref) {
                          var props = ref.props
                          return [
                            _c(
                              "td",
                              { staticStyle: { width: "100%", padding: "0" } },
                              [
                                _c("div", { staticClass: "action-cell" }, [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip.left-start",
                                          value: {
                                            content: "Download Document",
                                            container: false,
                                            classes: ["tooltip"],
                                          },
                                          expression:
                                            "{ content: 'Download Document', container: false, classes: ['tooltip'] }",
                                          modifiers: { "left-start": true },
                                        },
                                      ],
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.download(
                                                props.dataItem.id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-download",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip.left-start",
                                          value: {
                                            content: "View Document",
                                            container: false,
                                            classes: ["tooltip"],
                                          },
                                          expression:
                                            "{ content: 'View Document', container: false, classes: ['tooltip'] }",
                                          modifiers: { "left-start": true },
                                        },
                                      ],
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.viewDocument(
                                                props.dataItem
                                              )
                                            },
                                          },
                                        },
                                        [_c("i", { staticClass: "fa fa-eye" })]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
        ]),
      ]),
      _vm.showDeleteModal
        ? _c(
            "div",
            { staticClass: "modal is-active", attrs: { id: "delete-modal" } },
            [
              _c("div", {
                staticClass: "modal-background",
                on: {
                  click: function ($event) {
                    _vm.showDeleteModal = false
                  },
                },
              }),
              _c("div", { staticClass: "modal-card" }, [
                _c("header", { staticClass: "modal-card-head has-bg-danger" }, [
                  _c("div", { staticClass: "modal-card-title has-bg-danger" }, [
                    _vm._v("Confirm Delete"),
                  ]),
                  _c("a", {
                    staticClass: "delete",
                    attrs: { "aria-label": "close" },
                    on: {
                      click: function ($event) {
                        _vm.showDeleteModal = false
                      },
                    },
                  }),
                ]),
                _c("section", { staticClass: "modal-card-body" }, [
                  _vm._m(2),
                  _c("div", { staticClass: "field" }, [
                    _c("div", { staticClass: "control" }, [
                      _c("input", {
                        directives: [
                          { name: "focus", rawName: "v-focus" },
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.confirmationText,
                            expression: "confirmationText",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "input",
                        attrs: {
                          type: "text",
                          placeholder: "type `delete` and then click confirm",
                        },
                        domProps: { value: _vm.confirmationText },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            $event.preventDefault()
                            return _vm.deleteIfConfirmed.apply(null, arguments)
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.confirmationText = $event.target.value.trim()
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
                _c("footer", { staticClass: "modal-card-foot" }, [
                  _c(
                    "a",
                    {
                      staticClass: "button is-danger",
                      attrs: { disabled: _vm.confirmationText !== "delete" },
                      on: { click: _vm.deleteIfConfirmed },
                    },
                    [_vm._m(3), _c("span", [_vm._v("Confirm")])]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "button",
                      on: {
                        click: function ($event) {
                          _vm.showDeleteModal = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-plus-square" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-plus-square" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content" }, [
      _vm._v(' Type "'),
      _c("b", [_vm._v("delete")]),
      _vm._v('" to confirm that you want to delete one or more documents. '),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-trash-alt" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }